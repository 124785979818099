import React, {useContext, useEffect, useState} from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import routes from './routes.json'
import {ProductContext, ProductReturn} from '../../../../app/GeneraleVariables'
import {ActiveProductsType, useUser} from '../../../../app/modules/context/types'

interface MenuConfig {
    title: string;
    icon: string;
    createUrl: string;
    activeUrl: string;
}

interface MenuRoute {
    primary: string;
    secondary?: {to: string; title: string}[];
}

interface Routes {
    CallTracking: MenuRoute;
    CallTrackingStatistics: MenuRoute;
    WebForms: MenuRoute;
    Mailing: MenuRoute;
    Statistics: MenuRoute;
    Contacts: MenuRoute;
    Report: MenuRoute;
    [key: string]: MenuRoute;
}


const menuConfig: { [key in keyof ActiveProductsType]: MenuConfig } = {
    Calltraking: {
        title: 'Journal des appels',
        icon: '/media/icons/duotune/communication/com014.svg',
        createUrl: '/creer-compte-calltracking',
        activeUrl: '/call-tracking/journal-appels',
    },
    Webformulaires: {
        title: 'Vos formulaires',
        icon: '/media/icons/duotune/general/gen005.svg',
        createUrl: '/creer-compte-webformulaires',
        activeUrl: '/web-formulaires/tableau-de-bord-wf',
    },
    Mailing: {
        title: 'Vos campagnes e-mails',
        icon: '/media/icons/duotune/communication/com002.svg',
        createUrl: '/creer-compte-mailing',
        activeUrl: '/mailing',
    },
    Statistiques: {
        title: 'Référencement naturel',
        icon: '/media/icons/duotune/general/gen032.svg',
        createUrl: '/creer-compte-statistiques',
        activeUrl: '/statistiques/s-seo',
    },
    Ereputaion: {
        title: '',
        icon: '/',
        createUrl: '/',
        activeUrl: '/',
    },
    Sav: {
        title: 'Support',
        icon: '/media/icons/duotune/coding/cod001.svg',
        createUrl: '/',
        activeUrl: '/sav/accueil',
    },
    UpdatePlugins: {
        title: 'Mises à jour du site web',
        icon: '/media/icons/duotune/arrows/arr093.svg',
        createUrl: '/',
        activeUrl: '/wp',
    },

}

export type ProductStates = {
    [key in keyof ActiveProductsType]: boolean;
};

const initialProducts: ProductStates = {
    Calltraking: false,
    Webformulaires: false,
    Mailing: false,
    Ereputaion: false,
    Statistiques: false,
    Sav: false,
    UpdatePlugins: false,
};

export function AsideMenuMain() {


    const {personalInformation: {googleAnalytics, products_active}} = useUser()

    const [accountStatuses, setAccountStatuses] = useState<ProductStates>(initialProducts)

    useEffect(() => {
        const initialAccountStatuses: ProductStates = Object.fromEntries(
            (Object.keys(products_active) as (keyof ActiveProductsType)[]).map((key) => [
                key,
                products_active[key].activated_at !== null && products_active[key].activated_at !== '',
            ]),
        ) as ProductStates

        setAccountStatuses(initialAccountStatuses)
    }, [products_active])

    return (
        <>
            <AsideMenuItem
                to="/dashboard"
                icon="/media/icons/duotune/art/art002.svg"
                title="Tableau de bord"
                fontIcon="bi-app-indicator"
            />

            {Object.entries(menuConfig).map(([key, config]) => {

                const route = (routes as Routes)[key]
                const isActive = accountStatuses[key as keyof ProductStates]

                return isActive && (
                    key === 'Mailing' ? (
                        <AsideMenuItemWithSub
                            key={key}
                            to={route.primary}
                            title={config.title}
                            icon={config.icon}
                            fontIcon="bi-layers"
                        >
                            {route.secondary?.map((item, index) => (
                                <AsideMenuItem
                                    to={`${route.primary}${item.to}`}
                                    title={item.title}
                                    hasBullet={true}
                                    key={index}
                                />
                            ))}
                        </AsideMenuItemWithSub>
                    ) : (
                        <AsideMenuItem
                            key={key}
                            to={config.activeUrl}
                            icon={config.icon}
                            title={config.title}
                            fontIcon="bi-layers"
                        />
                    )
                )
            })}

            <AsideMenuItem
                to={routes.Contacts.primary}
                icon="/media/icons/duotune/communication/com013.svg"
                title="Contacts"
                fontIcon="bi-layers"
            />

            <AsideMenuItem
                to={"/clicks"}
                icon="/media/icons/duotune/coding/cod006.svg"
                title="Conversion Clics"
                fontIcon="bi-layers"
            />

            {
                googleAnalytics
                    ? <AsideMenuItem
                        to={routes.Report.primary}
                        icon="/media/icons/duotune/general/gen062.svg"
                        title="Rapport"
                        fontIcon="bi-layers"
                    /> : <></>
            }
        </>
    )
}