import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {useNotifications, useUser} from '../../../../app/modules/context/types'
import {stripHtml, truncateText} from '../../../../app/pages/report/utils/functions'

const HeaderNotificationsMenu: FC = () => {

    const {notifications: {ticket_message}} = useNotifications()
    const {personalInformation: {workspace}} = useUser()

    const primary = workspace?.color.main ? workspace.color.main : '#e1006d'
    const secondary = workspace?.color.second ? workspace.color.second : '#0094f3'

    return (
        <div
            className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-400px"
            data-kt-menu="true"
        >
            <div
                className='d-flex flex-column bgi-no-repeat rounded-top'
                style={{background: `linear-gradient(55deg, ${primary}cc 0%, ${secondary}66 100%)`}}
            >
                <h3 className='text-white fw-bold px-9 mt-7 mb-6'>
                    Notifications <span className='fs-6 opacity-75 ps-3'>{ticket_message?.count}</span>
                </h3>

                <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
                    <li className='nav-item'>
                        <a
                            className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
                            data-bs-toggle='tab'
                            href='#kt_topbar_notifications_1'
                        >
                            SAV
                        </a>
                    </li>
                </ul>
            </div>

            <div className='tab-content'>
                <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
                    <div className='scroll-y mh-325px px-3 pt-5'>

                        {
                            ticket_message?.data.map((item, index) => (
                                <div className="row mb-5" key={index}>
                                    <div className="col-3">
                                        <span className="fs-7">{item.created_at}</span>
                                    </div>
                                    <div className="col-9">
                                        <div className="flex-grow-1">
                                            <Link to={`sav/ticket/${item.ticket_id}`}
                                                  className="text-gray-800 text-hover-primary fw-bolder fs-6">{truncateText(item.subject, 20)}</Link>
                                            <span
                                                className="text-muted fw-bold d-block">{stripHtml(item.message)}</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>

                </div>
            </div>
        </div>
    )
}

export {HeaderNotificationsMenu}
