import {toAbsoluteUrl} from '../../../helpers'
import React from 'react'
import {useUser} from '../../../../app/modules/context/types'

export function FallbackView() {

    const {personalInformation: {workspace}} = useUser()

    const splashScreen = workspace?.logo ? workspace.logo : toAbsoluteUrl('/media/logos/Logo-WF_2.png')

    return (
        <div className="splash-screen">
            <img src={splashScreen} alt="Start logo" className="w-100px" />
        </div>
    )
}
