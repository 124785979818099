import React, {FC, useEffect, useState} from 'react';
import {Link, Redirect, useHistory, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from "../../../../_metronic/helpers";
import clsx from "clsx";
import * as Yup from 'yup';
import {useFormik} from "formik";
import SVG from "react-inlinesvg";
import PasswordReset from "../../../modules/functions/post/forgot-password/PasswordReset";

interface SlugReset {
    slug: string;
    email: string;
}

const ResetPasswordSchema = Yup.object().shape({
   password: Yup.string()
        .min(8, 'Au moins 8 symboles')
        .max(50, 'Maximum 20 symboles')
        .required('Mot de passe requis'),
        // .matches(/^.*(?=.{3,})(?=.*[a-z]{1,})(?=.*[A-Z]{1,})(?=.*[0-9]{1,})(?=.*[\d])(?=.*[.;%!#]).*$/,
        //     { message: 'Format incorrect', excludeEmptyString: true }),
    passwordConfirmation: Yup.string()
        .min(8, 'Au moins 8 symboles')
        .max(50, 'Maximum 20 symboles')
        .required('Mot de passe requis')
        .oneOf([Yup.ref('password'), null], 'Les mots de passe ne sont pas identiques'),
});
const initialValues = {
    email: '',
    password: '',
    passwordConfirmation: '',
    token: '',
}
const ResetPassword: FC = () => {

    const history = useHistory();
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [color, setColor] = useState('');
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const slugReset:SlugReset = useParams();

    const slugEmail = slugReset.email;
    const slugToken = slugReset.slug;

    useEffect(() => {

        initialValues.email = slugEmail;
        initialValues.token = slugToken;

        setEmail(slugEmail)
        setToken(slugToken)
    }, []);



    const formik = useFormik({

        initialValues,
        validationSchema: ResetPasswordSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {

            setLoading(true);
            setTimeout(() => {

                let dataObject = {
                    token: values.token,
                    email: values.email,
                    password: values.password,
                    password_confirmation: values.passwordConfirmation,
                };

                PasswordReset(dataObject,
                    setSubmitting,
                    setStatus,
                    setColor,
                    'Ce jeton de réinitialisation de mot de passe n\'est pas valide.',
                    'Votre mot de passe a été changé avec succès',
                    setRedirect
                );
            }, 100);
        }
    });

    if (redirect) {
        return <Redirect to='/login' />
    }

    return (
        <>
            <div
                className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            >
                {/* begin::Content */}
                <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                    {/* begin::Logo */}
                    <div className='mb-10'>
                        <img alt='Logo' src={toAbsoluteUrl('/media/logos/Logo-WF_2.png')} className='h-75px' />
                    </div>

                    <div className="w-275px w-sm-500px d-flex bg-light-info rounded border-info border border-dashed p-7 mb-10">
                        <div className="text-info">
                           <strong>Choisissez ici un nouveau mot de passe pour votre compte</strong>
                        </div>
                    </div>
                    {/* end::Logo */}
                    {/* begin::Wrapper */}
                    <div className='w-sm-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>

                        <form
                            className='form w-100 py-5'
                            noValidate
                            id='kt_forgot_password_form'
                            onSubmit={formik.handleSubmit}
                        >
                            {formik.status ? (

                                <div className={`alert alert-${color} d-flex align-items-center p-5 mb-10`}>
                                <span className={`svg-icon svg-icon-2hx svg-icon-${color} me-2`}>
                                    <span className={`svg-icon svg-icon-2hx svg-icon-${color} me-4`}>
                                        {(color === 'danger') && <SVG src={toAbsoluteUrl('/media/icons/duotune/general/gen050.svg')}/>}
                                        {(color === 'success') && <SVG src={toAbsoluteUrl('/media/icons/duotune/general/gen048.svg')}/>}
                                    </span>
                                </span>
                                    <div className="d-flex flex-column">
                                        <span>{formik.status}</span>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {/* begin::E-mail */}
                            <div className='fv-row mb-10'>
                                <label className='form-label fs-6 fw-bolder text-dark'>E-mail</label>
                                <input
                                    placeholder='Email'
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                    )}
                                    type='email'
                                    autoComplete='off'
                                    {...formik.getFieldProps('email')}
                                    readOnly
                                    disabled
                                />
                            </div>
                            {/* end::E-mail */}

                            {/* begin::Password */}
                            <div className='fv-row mb-10'>
                                <label className='form-label fs-6 fw-bolder text-dark'>Mot de passe</label>
                                <input
                                    placeholder='Mot de passe'
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                    )}
                                    type='password'
                                    autoComplete='off'
                                    {...formik.getFieldProps('password')}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <div className='fv-plugins-message-container my-1'>
                                        <div className='fv-help-block'>
                                            <span role='alert' className="text-danger p-2">{formik.errors.password}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* end::Password */}

                            {/* begin::Password Confirmation*/}
                            <div className='fv-row mb-10'>
                                <label className='form-label fs-6 fw-bolder text-dark'>Confirmation mot de passe</label>
                                <input
                                    placeholder='Confirmation mot de passe'
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                    )}
                                    type='password'
                                    autoComplete='off'
                                    {...formik.getFieldProps('passwordConfirmation')}
                                />
                                {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                                    <div className='fv-plugins-message-container my-1'>
                                        <div className='fv-help-block'>
                                            <span role='alert' className="text-danger p-2">{formik.errors.passwordConfirmation}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* end::Password Confirmation*/}

                            {/* begin::Token*/}

                            <input
                                placeholder='confirmation mot de passe'
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                )}
                                type='text'
                                autoComplete='off'
                                {...formik.getFieldProps('token')}
                                readOnly
                                disabled
                                hidden
                            />

                            {/* end::Token*/}


                            {/* begin::Action */}
                            <div className='text-center'>
                                <button
                                    type='submit'
                                    id='kt_forgot_password_submit'
                                    className='btn btn-lg btn-primary w-100 mb-5'
                                    disabled={formik.isSubmitting || !formik.isValid}
                                >
                                    {!loading && <span className='indicator-label'>Continuer</span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                            Please wait...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2' />
                                        </span>
                                    )}
                                </button>
                            </div>
                            {/* end::Action */}
                        </form>
                    </div>
                    {/* end::Wrapper */}
                </div>
                {/* end::Content */}

            </div>
        </>
    )
}

export {ResetPassword}
