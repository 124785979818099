import React, {FC, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu} from '../../../partials'
import {useNotifications, useUser} from '../../../../app/modules/context/types'
import {rotate_0, rotate_180} from '../../../../app/modules/all-pages'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
    toolbarButtonIconSizeClass = 'svg-icon-1 svg-icon-primary'

const Topbar: FC = () => {

    const {personalInformation: {firstname, lastname, images}} = useUser()
    const {notifications: {ticket_message}} = useNotifications()
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const toggleRef = useRef<HTMLDivElement | null>(null);
    const image = images.length ? images[0]['path'] : toAbsoluteUrl('/media/avatars/blank.png')

    useEffect(() => {
        const element = toggleRef.current;

        if (element) {
            const observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.type === "attributes" && mutation.attributeName === "class") {
                        const hasShowClass = element.classList.contains("show");
                        setIsMenuOpen(hasShowClass);
                    }
                });
            });

            observer.observe(element, { attributes: true });

            return () => {
                observer.disconnect();
            };
        }
    }, []);

    return (
        <div className="d-flex align-items-stretch flex-shrink-0">

            {/* NOTIFICATIONS */}
            <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                {/* begin::Menu- wrapper */}
                <div
                    className={clsx(
                        'btn btn-icon btn-active-light-primary position-relative',
                        toolbarButtonHeightClass,
                    )}
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="bottom"
                >
                    <KTSVG
                        path="/media/icons/duotune/general/gen007.svg"
                        className={toolbarButtonIconSizeClass}
                    />
                    {
                        ticket_message?.count
                            ? <span
                                className="badge badge-circle fs-7 p-2 bg-light-danger text-danger position-absolute translate-middle top-0 start-75 animation-blink">{ticket_message?.count}</span>
                            : <></>
                    }

                </div>
                <HeaderNotificationsMenu />
                {/* end::Menu wrapper */}
            </div>

            {/* begin::User */}
            <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                 id="kt_header_user_menu_toggle"
            >
                {/* begin::Toggle */}
                <div
                    ref={toggleRef}
                    className={clsx(
                        'cursor-pointer symbol d-flex align-items-center',
                        toolbarUserAvatarHeightClass,
                        { 'show menu-dropdown': isMenuOpen }
                    )}
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="bottom"
                >
                    <div className="d-flex flex-column justify-content-between align-items-end px-2">
                        <span
                            className="cursor-pointer text-gray-600 text-hover-primary fs-7 fs-sm-6 text-capitalize">
                            {firstname} {lastname}
                        </span>
                        <span style={isMenuOpen ? rotate_180 : rotate_0}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr072.svg"
                                className="svg-icon svg-icon svg-icon-2"

                            />
                        </span>
                        {/*<KTSVG path="/media/icons/duotune/arrows/arr072.svg" className="svg-icon svg-icon svg-icon-2" />*/}
                    </div>

                    <img src={image} alt="MyWebforce" style={{objectFit: 'cover'}} />

                </div>
                <HeaderUserMenu />
                {/* end::Toggle */}
            </div>
            {/* end::User */}

        </div>
    )
}

export {Topbar}