import React, {FC, useContext, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ChartsWidget7, ChartsWidget8} from '../../../_metronic/partials/widgets'
import {ProductContext, ProductReturn} from '../../GeneraleVariables'
import {ValueType} from 'rsuite/cjs/DateRangePicker/types'
import {subDays} from 'date-fns'
import {DateRange, PageHeader, usePeriod, UseWindowSize} from '../../modules/all-pages'
import ChartDashboard1 from './components/ChartDashboard1'
import {ChartDashboard4} from './components/ChartDashboard4'
import {ContactsDashboard} from './components/ContactsDashboard'
import {useDashboardQueries} from './useDashboardQueries'
import {initialData, InterfaceData} from './ts'
import {loadingState} from '../../../global'
import {Announcement} from './components/Announcement'
import {ActiveProductsType, useUser} from '../../modules/context/types'
import {ProductStates} from '../../../_metronic/layout/components/aside/AsideMenuMain'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

const styles = {
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridTemplateRows: '1fr',
        gridGap: 10,
    },
    gridMd: {
        gridTemplateColumns: 'repeat(2, 1fr)',
    },
    gridSm: {
        gridTemplateColumns: 'repeat(2, 1fr)',
    },
}

const initialProducts: ProductStates = {
    Calltraking: false,
    Webformulaires: false,
    Mailing: false,
    Ereputaion: false,
    Statistiques: false,
    Sav: false,
    UpdatePlugins: false,
}

const DashboardPage: FC = () => {

    const productsStatus = useContext<ProductReturn[]>(ProductContext)

    const {personalInformation: {products_active}} = useUser()

    const [accountStatuses, setAccountStatuses] = useState<ProductStates>(initialProducts)

    useEffect(() => {
        const initialAccountStatuses: ProductStates = Object.fromEntries(
            (Object.keys(products_active) as (keyof ActiveProductsType)[]).map((key) => [
                key,
                products_active[key].activated_at !== null && products_active[key].activated_at !== '',
            ]),
        ) as ProductStates

        setAccountStatuses(initialAccountStatuses)
    }, [products_active])


    const [rangeValue, setRangeValue] = React.useState<ValueType>([subDays(new Date(), 29), new Date()]),
        [loadingData, setLoadingData] = useState<'idle' | 'pending' | 'fulfilled' | 'rejected'>('idle'),
        [data, setData] = useState<InterfaceData>({...initialData}),
        [loading, setLoading] = useState<loadingState>('idle'),
        [dateTimeStart, dateTimeEnd] = rangeValue,
        excludedNames = ['Mailing', 'Agenda', 'Ereputaion', 'Statistiques']

    const period = usePeriod(dateTimeStart, dateTimeEnd)

    // const statusTrue = useMemo(
    //     () => productsStatus.filter(item =>
    //         item.status === 1 && !excludedNames.includes(item.name) || item.status === 0 && ['Calltraking'].includes(item.name),
    //     ),
    //     [excludedNames, productsStatus],
    // )

    useDashboardQueries({period, statusTrue: accountStatuses, setData, setLoading})


    const {innerWidth} = UseWindowSize()
    const getResponsiveStyles = () => {
        if (innerWidth <= 480) {
            return {...styles.grid, ...styles.gridSm}
        } else if (innerWidth <= 768) {
            return {...styles.grid, ...styles.gridMd}
        } else {
            return styles.grid
        }
    }


    return (
        <>

            <PageHeader icon={"/media/icons/duotune/art/art002.svg"}
                        title={"Activité et performances de votre site web"}
                        description={"Ce tableau de bord vous offre une vue claire et concise de l'activité de votre site sur la période sélectionnée. Suivez vos indicateurs clés comme les visites, les conversions, les formulaires soumis et les appels téléphoniques"}
            />

            {/* RangeValue */}
            <div className="row mb-8">
                <div className="col-12 d-flex justify-content-end align-items-center">

                    <DateRange rangeValue={rangeValue}
                               setRangeValue={setRangeValue}
                               loadingStatus={loading}
                               setLoadingStatus={(status) => setLoadingData(status)}
                               className="d-flex justify-content-end align-items-center"
                    />
                </div>
            </div>

            <div className="mb-5" style={getResponsiveStyles()}>
                <div>
                    <ChartDashboard1 bg={0}
                                     iconPath={'/media/icons/duotune/general/gen060.svg'}
                                     body={{total: data.numberVisit, text: 'Visites'}}
                                     to={'/statistiques/s-seo'}
                        // footer={{start: period.start, end: period.end}}
                    />
                </div>
                <div>
                    <ChartDashboard1 bg={1}
                                     iconPath={'/media/icons/duotune/arrows/arr029.svg'}
                                     body={{total: data.conversion, text: 'Conversions'}}
                        // footer={{start: period.start, end: period.end}}
                    />
                </div>
                <div>
                    <ChartDashboard1 bg={0}
                                     iconPath={'/media/icons/duotune/general/gen005.svg'}
                                     body={{total: data.allFormsReceived, text: 'Formulaires'}}
                                     to={'/web-formulaires/tableau-de-bord-wf'}
                        // footer={{start: period.start, end: period.end}}
                    />
                </div>
                <div>
                    <ChartDashboard1 bg={1}
                                     iconPath={'/media/icons/duotune/communication/com014.svg'}
                                     body={{total: data.cleverPhone.generalCallReceived, text: 'Appels'}}
                                     to={'/call-tracking/journal-appels'}
                    />
                </div>
                <div>
                    <ChartDashboard1 bg={0}
                                     iconPath={'/media/icons/duotune/coding/cod006.svg'}
                                     body={{
                                         total: data.clicksNumber,
                                         text: 'Nombre de clics sur le téléphone',
                                     }}
                                     to={'/clicks'}
                    />
                </div>
            </div>

            <div className="row d-flex justify-content-center">
                <div className="col-xl-4 card card-xl-stretch">
                    <ContactsDashboard />
                </div>
                <div className="col-xl-8">
                    <ChartDashboard4 className={'card-xl-stretch'}
                                     chartOptions={data.chartOptions}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Announcement />
                </div>
            </div>


            {/*begin::ROW-04 */
            }
            {/*card cursor */
            }
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-6">
                    <ChartsWidget7 className={'card-xl-stretch'} />
                </div>
                <div className="col-xl-6">
                    <ChartsWidget8 className={'card-xl-stretch'} />
                </div>
            </div>
            {/*end::ROW-04 */
            }


        </>
    )
}


const DashboardWrapper: FC = () => {

    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <DashboardPage />
        </>
    )
}

export {DashboardWrapper}