import {Modal, Ratings} from '../../../modules/all-pages'
import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import SVG from 'react-inlinesvg'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useUser} from '../../../modules/context/types'
import {IdParams} from '../../../../_metronic/partials/widgets/tables/Mailing/settings/contacts/Types'
import {useParams} from 'react-router-dom'
import SetupAxios from '../../../../setup/axios/SetupAxios'
import {Satisfaction} from '../types'

const validationSchema = Yup.object({
    rating: Yup.string().required('Rating is required'),
    comments: Yup.string(),
})

interface FormValues {
    rating: string;
    comments: string;
}

const initialValues: FormValues = {
    rating: '',
    comments: '',
}

const SEND_SATISFACTION = process.env.REACT_APP_SAV_TICKETS_SATISFACTION_TICKET || 'sav/satisfaction'

const postData = async (formData: FormData): Promise<any> => {
    const response = await SetupAxios.post(SEND_SATISFACTION, formData, {
        headers: {'Content-Type': 'application/json'},
    })
    return response.data
}

interface SatisfactionTicketModalInterface {
    satisfaction: Satisfaction | null;
    status: string;
}
const SatisfactionTicketModal: FC<SatisfactionTicketModalInterface> = ({satisfaction, status}) => {

    const {personalInformation: {id: userId}} = useUser()
    const {id: ticketId}: IdParams = useParams()

    const modalCloseRef = useRef<HTMLButtonElement | null>(null)
    const modalOpenRef = useRef<HTMLButtonElement | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [hiddenModal, setHiddenModal] = useState<boolean>(false)

    const handleModalOpen = useCallback(
        () => {
            if (modalOpenRef.current) modalOpenRef.current?.click()
        },
        [],
    )

    const handleModalClose = useCallback(
        () => {
            if (modalCloseRef.current) modalCloseRef.current?.click()
        },
        [],
    )

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, {resetForm, setSubmitting, setStatus}) => {

            setLoading(true)
            setSubmitting(false)

            const formData = new FormData()
            formData.append('client_id', `${userId}`)
            formData.append('sav_ticket_id', ticketId)
            formData.append('rating', values.rating)
            formData.append('comments', values.comments)

            try {

                const response = await postData(formData)
                if (response.status === 200) {

                    handleModalClose()
                    setHiddenModal(true)
                    setLoading(false)
                    setTimeout(() => resetForm(), 700)
                }
            } catch (error) {
                console.error('Error posting data:', error)
                throw error
            }

        },
    })

    useEffect(() => {

            setTimeout(() => {
                if (satisfaction === null && status === 'Fermé' && !hiddenModal) handleModalOpen()
            }, 1000)
        },
        [handleModalOpen, hiddenModal, satisfaction, status])

    return (
        <>
            <button type="button"
                    className="d-none"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_satisfaction_ticket"
                    ref={modalOpenRef}
            >
                <KTSVG path="/media/icons/duotune/general/gen035.svg" className="svg-icon-2 me-2" />
                open modal
            </button>
            <Modal id="satisfaction_ticket"
                   title="Satisfaction"
                   media="md"
                   body={
                       <form id="kt_modal_satisfaction_ticket"
                             className="form fv-plugins-bootstrap5 fv-plugins-framework"
                             onSubmit={formik.handleSubmit}>


                           <Ratings ratingsLength={5} ratingSize={'fs-1'} formik={formik} fieldName={'rating'} />

                           <textarea className="form-control form-control-solid mb-3"
                                     rows={5}
                                     data-kt-element="input"
                                     placeholder="Type a message"
                                     {...formik.getFieldProps('comments')}
                           />

                           {formik.status && (
                               <div className="alert alert-success d-flex align-items-center p-2 mb-7">
                                <span className="svg-icon svg-icon-2hx svg-icon-success mx-2">
                                    <SVG src={toAbsoluteUrl('/media/icons/duotune/general/gen048.svg')} />
                                </span>
                                   <div className="d-flex flex-column">
                                       <span>{formik.status}</span>
                                   </div>
                               </div>
                           )}

                           <div className="text-center">
                               <button type="button" className="btn btn-sm btn-light me-2 px-6" data-bs-dismiss="modal"
                                       ref={modalCloseRef}
                                       onClick={handleModalClose}
                               >
                                   Fermer
                               </button>
                               <button type="submit"
                                       id="kt_modal_new_ticket_submit"
                                       className="btn btn-primary"
                                       disabled={formik.isSubmitting || !formik.isValid}
                               >
                                   {!loading && <span className="indicator-label">Envoyer</span>}
                                   {loading && (
                                       <span className="indicator-progress" style={{display: 'block'}}>
                                        S'il vous plaît, attendez...
                                        <span
                                            className="spinner-border spinner-border-sm align-middle ms-2" />
                                   </span>
                                   )}
                               </button>
                           </div>
                       </form>
                   }
            />
        </>
    )
}


export {SatisfactionTicketModal}