import {QueryKey, useQueries, UseQueryOptions, UseQueryResult} from 'react-query'
import {loadingState, PeriodType, ProductReturn} from '../../../global'
import {Dispatch, SetStateAction} from 'react'
import {Get} from '../../modules/functions/get/Get'
import {InterfaceData} from './ts'
import {ProductStates} from '../../../_metronic/layout/components/aside/AsideMenuMain'

const DASHBOARD_CONVERSION = process.env.REACT_APP_DASHBOARD_CONVERSION || 'conversion'
const CALL_TRACKING = process.env.REACT_APP_CALL_TRACKING_GENERAL || 'calltraking-statistic'
const WEB_FORMS = process.env.REACT_APP_WEB_FORMS_HOME_WEB_FORMS || 'statistiques-generales'
const STATISTICS_VISIT = process.env.REACT_APP_STATISTICS_VISIT || 'statistics-visit'
const CALL_LOG = process.env.REACT_APP_CALL_TRACKING_CALL_LOG || 'calltraking-journal-appel'
const ANNOUNCEMENT = process.env.REACT_APP_DASHBOARD_ANNOUNCEMENT || 'v2/annonce-homepage'
const STATISTICS_CLICK_NUMBER = process.env.REACT_APP_STATISTICS_CLICK_NUMBER || 'statistics-click-number'

type useDashboardQueriesType = {
    period: PeriodType
    statusTrue: ProductStates
    setData: Dispatch<SetStateAction<InterfaceData>>
    setLoading: Dispatch<SetStateAction<loadingState>>
}

const initialProducts: ProductStates = {
    Calltraking: false,
    Webformulaires: false,
    Mailing: false,
    Ereputaion: false,
    Statistiques: false,
    Sav: false,
    UpdatePlugins: false,
}

const getEndpointForProduct = (productName: string) => {
    switch (productName) {
        case 'Calltraking':
            return CALL_TRACKING;
        case 'Webformulaires':
            return WEB_FORMS;
        default:
            return '';  // Default if no endpoint is defined
    }
}

type ActiveProductsType = {
    Calltraking: boolean;
    Webformulaires: boolean;
    Mailing: boolean;
    Ereputaion: boolean;
    Statistiques: boolean;
    Sav: boolean;
    UpdatePlugins: boolean;
}

export const useDashboardQueries = ({
                                        period,
                                        statusTrue,
                                        setData,
                                        setLoading,
                                    }: useDashboardQueriesType): UseQueryResult[] => {

    // const queries = statusTrue.map(product => {
    //     const queryKey: QueryKey = [`dashboard_${product.name.toLowerCase()}`, period];
    //     const endpoint = product.name === 'Calltraking' ? CALL_TRACKING : WEB_FORMS;
    //     return {
    //         queryKey,
    //         queryFn: () => Get<any>({endpoint, period}),
    //         enabled: !!period,
    //         select: (data: any) => (product.name === 'Calltraking' ? data : data.runningTotalFormReceived),
    //         onSuccess: (data: any) => onSuccessQueries({data, name: product.name, setData, setLoading}),
    //         onError,
    //     };
    // })

    const selectedProducts = ["Calltraking", "Webformulaires"]; // Only these products are active

    const activeProducts = Object.keys(statusTrue).filter(productKey => statusTrue[productKey as keyof ActiveProductsType]);

    const queries = activeProducts
        .map(productKey => {

            const isActive = statusTrue[productKey as keyof ActiveProductsType]; // Type safe lookup

            if (!isActive) return null;

            const queryKey: QueryKey = [`dashboard_${productKey.toLowerCase()}`, period];
            const endpoint = productKey === 'Calltraking' ? CALL_TRACKING : WEB_FORMS;

            return {
                queryKey,
                queryFn: () => Get<any>({ endpoint, period }),
                enabled: !!period,
                onSuccess: (data: any) => onSuccessQueries({ data, name: productKey, setData, setLoading }),
                onError,
            };
        })
        .filter(query => query !== null);


    const additionalQueries = [
        {name: 'conversion', endpoint: DASHBOARD_CONVERSION},
        {name: 'statistics_visit', endpoint: STATISTICS_VISIT},
        {name: 'call_log', endpoint: CALL_LOG},
        {name: 'announcement', endpoint: ANNOUNCEMENT},
        {name: 'clicks_number', endpoint: STATISTICS_CLICK_NUMBER},
    ].map(query => {
        const queryKey: QueryKey = [`dashboard_${query.name.toLowerCase()}`, period]
        return {
            queryKey,
            queryFn: () => Get({endpoint: query.endpoint, period}),
            enabled: !!period,
            select: (data: any) => data,
            onSuccess: (data: any) => onSuccessAdditionalQueries({data, name: query.name, setData, setLoading}),
            onError,
        }
    })

    const allQueries = [...queries, ...additionalQueries];

    return useQueries(allQueries as UseQueryOptions<any, any, any, QueryKey>[]);
}

type QueriesType = {
    data: any
    name: string
    setData: Dispatch<SetStateAction<InterfaceData>>
    setLoading: Dispatch<SetStateAction<loadingState>>
}

const onSuccessQueries = ({data, name, setData, setLoading}: QueriesType) => {

    setData(prevData => {
        if (name === 'Calltraking') {
            return {
                ...prevData,
                cleverPhone: {generalCallReceived: data.generalAppelRecu},
            }
        } else if (name === 'Webformulaires') {
            return {...prevData, allFormsReceived: data}
        }
        return prevData
    })
    setLoading('fulfilled')
}

const onError = (error: any) => {
    console.log('query: ', {error})
}

const onSuccessAdditionalQueries = ({data, name, setData, setLoading}: QueriesType) => {

    setData(prevData => {
        if (name === 'conversion') {
            return {...prevData, conversion: data}
        } else if (name === 'statistics_visit') {

            if (!data) {
                return {
                    ...prevData,
                    numberVisit: -11111111,
                    chartOptions: {
                        series: [],
                        categoriesXAxis: [],
                    },
                }

            }

            return {
                ...prevData,
                numberVisit: data.seoNombreVisiteurs,
                chartOptions: {
                    series: data.seoChartVisiteurs.total,
                    categoriesXAxis: data.seoChartVisiteurs.labels,
                },
            }
        } else if (name === 'call_log') {
            return {
                ...prevData,
                callLog: data,
            }
        } else if (name === 'announcement') {
            return {
                ...prevData, announcement: data.data.annonce_homepage_1,
            }
        } else if (name === 'clicks_number') {
            return {
                ...prevData,
                clicksNumber: data.total_click_number
            }
        } else {

            return {
                ...prevData,
            }
        }
    })
    setLoading('fulfilled')
}



