import {FC, useEffect, useState} from 'react'
import {ChildrenInterface} from '../../../global'
import {initialState, UserContext, UserInterface} from './types'
import {useQuery} from 'react-query'
import SetupAxios from '../../../setup/axios/SetupAxios'
import axios from 'axios'
import {API_URL} from '../all-pages'
import {useLocation} from 'react-router-dom'


const GET_USER = process.env.REACT_APP_GET_USER || 'get_user'

const fetchData_ = async (token: string): Promise<UserInterface> => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}${GET_USER}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        return response.data
    } catch (error) {
        console.error('Error during login:', error)
        throw error
    }
}

const fetchData = async (endpoint: string): Promise<UserInterface> => {
    try {
        const response = await SetupAxios.get<UserInterface>(endpoint)
        const data = response.data

        // Save data to local storage with a timestamp
        localStorage.setItem('_userInterface', JSON.stringify({data, timestamp: Date.now()}))
        localStorage.setItem('_clientCreationDate', data.created_at)
        return data
    } catch (error) {
        console.error('Error fetching data:', error)
        throw error
    }
}

const updateFavicon = (faviconUrl: string) => {
    let link = document.querySelector('link[rel~=\'icon\']') as HTMLLinkElement | null

    if (!link) {
        link = document.createElement('link') as HTMLLinkElement
        link.rel = 'icon'
        document.head.appendChild(link)
    }

    link.href = faviconUrl // Update the favicon URL
}


export const UserProvider: FC<ChildrenInterface> = ({children}) => {

    const [personalInformation, setPersonalInformation] = useState<UserInterface>(initialState)
    // const { isLoading, error, data } = useQuery('UserInfos', () => fetchData(GET_USER))

    const {pathname} = useLocation()
    const token = localStorage.getItem('_token')

    const segments = pathname.split('/token/')
    const newToken = segments[1]
    useEffect(() => {

        if (newToken) {
            fetchData_(newToken)
                .then(r => {
                    console.log('---------------------------  ', r)
                    localStorage.setItem('_userInterface', JSON.stringify({data: r, timestamp: Date.now()}))
                    localStorage.setItem('_clientCreationDate', r.created_at)
                    localStorage.setItem('_token', newToken)
                    setPersonalInformation(r)
                    if (r.workspace?.logo) updateFavicon(r.workspace.logo)
                    window.location.href = '/dashboard'
                })
                .catch((e) => {
                    console.log(e)
                })
        } else if (token) {
            fetchData_(token)
                .then(r => {
                    localStorage.setItem('_userInterface', JSON.stringify({data: r, timestamp: Date.now()}))
                    localStorage.setItem('_clientCreationDate', r.created_at)
                    localStorage.setItem('_token', token)
                    if (r.workspace?.logo) updateFavicon(r.workspace.logo)
                    setPersonalInformation(r)
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }, [newToken, token])

    // Load from localStorage initially
    // useEffect(() => {
    //     const cachedData = localStorage.getItem('_userInterface')
    //     if (cachedData) {
    //         const parsedData = JSON.parse(cachedData).data
    //         setPersonalInformation(parsedData)
    //     }
    // }, [])
    //
    // useQuery('UserInfos', () => fetchData(GET_USER), {
    //     onSuccess: (newData) => {
    //         setPersonalInformation(newData)
    //     },
    // })

    return (
        <UserContext.Provider value={{personalInformation, setPersonalInformation}}>
            {children}
        </UserContext.Provider>
    )
}

