import {useQuery} from 'react-query'
import {Get} from '../../modules/functions/get/Get'
import {initialWPData, Item, WPData} from './types'
import React, {useMemo, useState} from 'react'
import CountUp from 'react-countup'
import {KTSVG} from '../../../_metronic/helpers'
import {Card1, DateRange, PageHeader, usePeriod} from '../../modules/all-pages'
import {ValueType} from 'rsuite/cjs/DateRangePicker/types'
import {subDays} from 'date-fns'

const endpoint = process.env.REACT_APP_WP || 'wp_client_reports'

const Dashboard = () => {

    const [data, setData] = useState<WPData>(initialWPData)
    const [rangeValue, setRangeValue] = useState<ValueType>([subDays(new Date(), 29), new Date()])
    const [dateTimeStart, dateTimeEnd] = rangeValue
    const period = usePeriod(dateTimeStart, dateTimeEnd)
    const [loadingStatus, setLoadingStatus] = useState<'idle' | 'pending' | 'fulfilled' | 'rejected'>('idle')
    const queryPeriod = useMemo(() => period, [period])


    useQuery(
        ['DASHBOARD_WP', queryPeriod],
        () => Get<{data: WPData}>({endpoint, period: queryPeriod}),
        {
            onSuccess: (response) => {
                if (response && response.data) {
                    setData(response.data)
                    setLoadingStatus('fulfilled')
                } else {
                    console.warn('No data returned or data is undefined')
                }
            },
        },
    )

    const lengths: {key: keyof WPData, length: number, title: string}[] = [
        {key: 'wp', length: data.wp.length, title: 'Corps WordPress'},
        {key: 'plugin', length: data.plugin.length, title: 'Modules'},
        {key: 'theme', length: data.theme.length, title: 'Thèmes'},
    ]

    const sortedData = lengths.sort((a, b) => b.length - a.length);

    return (
        <>
            <PageHeader icon={'/media/icons/duotune/arrows/arr093.svg'}
                        title={'Suivi des mises à jour'}
                        description={'Cette section vous permet de suivre les mises à jour essentielles pour votre site WordPress, y compris le corps du CMS, les modules et les thèmes. Consultez les versions installées et les nouvelles mises à jour disponibles afin de maintenir votre site sécurisé, performant et à jour avec les dernières fonctionnalités.'}
            />
            <div className="card">
                <div className="card-header">
                    <div className="card-title">
                        <h3 className="fw-bolder m-0 fs-5 fs-sm-3">Mises à jour</h3>
                    </div>
                </div>
                <div className="card-body">
                    <div
                        className="row g-3 g-xl-10 d-flex justify-content-center align-items-end flex-column-reverse flex-md-row mb-10">
                        <div className="col-12 col-lg-8">
                            <div className="row">
                                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                                    <Card1 bg={0} countUp={data.wp.length} description={'Corps WordPress'} />
                                </div>
                                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                                    <Card1 bg={1} countUp={data.plugin.length} description={'Modules'} />
                                </div>
                                <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                                    <Card1 bg={0} countUp={data.theme.length} description={'Thèmes'} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 d-flex justify-content-end align-items-end mb-5 mb-md-0">
                            <DateRange rangeValue={rangeValue}
                                       setRangeValue={setRangeValue}
                                       loadingStatus={loadingStatus}
                                       setLoadingStatus={setLoadingStatus}
                                       className="d-flex justify-content-end align-items-center"
                            />
                        </div>
                    </div>

                    <div className="row">
                        {sortedData.map(({key, length, title}) => (
                            <div key={key} className="col-12 col-lg-6 col-xxl-4">
                                <Table DataItem={data[key]} title={title} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export {Dashboard}


const Table = ({DataItem, title}: {DataItem: Item[], title: string}) => (
    <div className="card card-xl-stretch mb-xl-8 border border-1 border-gray-300 border-dashed">
        <div className="card-header" style={{backgroundColor: 'rgba(225,243,255,0.3)'}}>
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3 mb-1">{title}</span>
            </h3>
        </div>
        <div className="card-body p-0">
            <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                    <tr className="fw-bolder text-muted bg-light">
                        <th className="ps-3 fw-bolder text-muted bg-light w-lg-125px">Date</th>
                        <th className="ps-3 fw-bolder text-muted bg-light w-lg-275px">Nom</th>
                        <th className="ps-3 fw-bolder text-muted bg-light text-center w-lg-200px">Versions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        DataItem.map((item, index) => (
                            <tr key={index}>
                                <td className="p-0">
                                    <span className="text-dark fw-bolder fs-7 p-3">{item.date}</span>
                                </td>
                                <td>
                                    <div className="d-flex flex-column w-100 me-2">
                                        <span className="text-muted me-2 fs-7 fw-bold">{item.name}</span>
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className="">
                                        <span
                                            className="badge badge-light-danger fw-bold fs-8 text-start">{item.version_before}</span>
                                        <KTSVG path={`/media/icons/duotune/arrows/arr064.svg`}
                                               className="svg-icon-4 svg-icon-primary mx-0" />
                                        <span
                                            className="badge badge-light-success fw-bold fs-8 text-end">{item.version_after}</span>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }

                    </tbody>
                </table>
            </div>
        </div>
    </div>
)