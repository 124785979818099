export interface StatsClicks {
    click_number: ClickNumber
    total_click_number: number
}

export interface ClickNumber {
    nombreClick: NumberClicks[]
}

export interface NumberClicks {
    eventCount: string
    dateHour: string
    browser: string
    pagePath: string
    city: string
    country: string
    platformDeviceCategory: string
    sessionSource: string
}

export const initialStatsClicks: StatsClicks = {
    total_click_number: 0,
    click_number: {
        nombreClick: [
            {
                eventCount: '',
                dateHour: '',
                browser: '',
                pagePath: '',
                city: '',
                country: '',
                platformDeviceCategory: '',
                sessionSource: '',
            },
        ],
    },
}
