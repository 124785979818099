export interface Item {
    date: string;
    type: string;
    name: string;
    slug: string;
    version_before: string;
    version_after: string;
}

export interface WPData {
    wp: Item[];
    theme: Item[];
    plugin: Item[];
}

export const initialWPData: WPData = {
    wp: [],
    theme: [],
    plugin: []
};