import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {SavProvider} from './context/SavProvider'
import Ticket from './Ticket'
import {Tickets} from './Tickets'


const SAVBreadCrumbs: Array<PageLink> = [
    {
        title: 'Support',
        path: '/sav/accueil',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]
const SAV = () => {

    return (
        <SavProvider>
            <Switch>
                    <Route path="/sav/accueil">
                        <PageTitle breadcrumbs={SAVBreadCrumbs}>Accueil</PageTitle>
                        <Tickets />
                    </Route>
                    <Route path="/sav/ticket/:id">
                        <PageTitle breadcrumbs={SAVBreadCrumbs}>Tickets</PageTitle>
                        <Ticket />
                    </Route>

                    <Redirect from="/sav/accueil" exact={true} to="/sav/accueil" />
                    <Redirect to="/sav/accueil" />
            </Switch>
        </SavProvider>

    )
}

export {SAV}