import React, {FC, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {ValueType} from 'rsuite/cjs/DateRangePicker/types'
import {subDays} from 'date-fns'
import {API_URL, DateRange, localStorageValue, PageHeader, usePeriod} from '../../../modules/all-pages'
import {QueryFunctionContext, QueryKey, useQueries, useQuery} from 'react-query'
import {ChildrenInterface, loadingState} from '../../../../global'
import {AdministrationData, initialState, InterfaceContext, ReportContext} from './types'
import {fetchAdministrationData, fetchDashboardData} from './functions'
import axios, {AxiosResponse} from 'axios'
import FileDownload from '../../../modules/functions/get/export/FileDownload'
import {useUser} from '../../../modules/context/types'

export interface Period {
    start: string;
    end: string;
}

const CONVERSION = process.env.REACT_APP_DASHBOARD_CONVERSION || 'conversion'
const SEO = process.env.REACT_APP_STATISTICS_SEO || 'statistics-seo'
const CALL_TRACKING = process.env.REACT_APP_CALL_TRACKING_GENERAL || 'calltraking-statistic'
const WEB_FORMS = process.env.REACT_APP_WEB_FORMS_HOME_WEB_FORMS || 'statistiques-generales'
const CAMPAIGN = process.env.REACT_APP_MAILING_STATISTICS_GLOBAL || 'v2/campagne-report'
const REPORT_PDF = process.env.REACT_APP_REPORT_PDF || 'v2/generate-report-pdf'

const formatSeoData = (data: any) => ({
    nombrePagesVues: data.vueAudience.nombrePagesVues,
    nombreVisites: data.vueAudience.nombreVisites,
    byChannels: data.acquisition.parCanaux,
    geographicOriginByCity: data.vueParGeographie.origineGeographiqueVille,
    mostViewedPage: data.contenu.pagesPlusConsultees,
});
const defaultSeoData = {
    nombrePagesVues: 0,
    nombreVisites: 0,
    byChannels: [],
    geographicOriginByCity: [],
    mostViewedPage: [],
}

const formatCallTracking = (data: any) => ({
    generalAppelRecu: data.generalAppelRecu,
    has_cleverPhone: data.has_cleverPhone,
    click_sur_tel: data.click_sur_tel,
});

const formatCampaignData = (data: any) => ({
    countMessages: data.countData.countMessages,
    countDataContacts: data.countData.countDataContacts,
});

export const ReportProvider: FC<ChildrenInterface> = ({children}) => {

    const [rangeValue, setRangeValue] = React.useState<ValueType>([subDays(new Date(), 29), new Date()]),
        [dateTimeStart, dateTimeEnd] = rangeValue,
        period = usePeriod(dateTimeStart, dateTimeEnd)

    const [data, setData] = useState<InterfaceContext>({
        ...initialState,
        loadingStatus: 'idle',
    })
    const [loadPdf, setLoadPdf] = useState<loadingState>('idle')
    const {personalInformation: {id}} = useUser()
    const [loadingStatus, setLoadingStatus] = useState<loadingState>('idle')

    const onSuccessReq = useCallback((dataReq: any, name: string) => {

        setData(prevData => ({
            ...prevData,
            [name]: dataReq,
            loadingStatus: name === 'seo' ? 'fulfilled' : prevData.loadingStatus,
        }))
        // setData(prevData => {
        //     const updatedData = {...prevData}
        //     switch (name) {
        //         case 'conversion':
        //             updatedData.conversion = dataReq
        //             break
        //         case 'seo':
        //             updatedData.seo = dataReq
        //             updatedData.loadingStatus = 'fulfilled'
        //             break
        //         case 'call_tracking':
        //             updatedData.callReceived = dataReq
        //             break
        //         case 'formsReceived':
        //             updatedData.formsReceived = dataReq
        //             break
        //         case 'campagne':
        //             updatedData.campagne = {
        //                 messagesReceived: dataReq.countMessages,
        //                 contacts: dataReq.countDataContacts,
        //             }
        //             break
        //         default:
        //             break
        //     }
        //     return updatedData
        // })
    }, [])

    const onError = useCallback((error: any) => {

        setData((prevData) => ({...prevData, loadingStatus: 'rejected'}))
    }, [])

    const queries = useMemo(() => [
        {name: 'conversion', endpoint: CONVERSION},
        {name: 'seo', endpoint: SEO},
        {name: 'callReceived', endpoint: CALL_TRACKING},
        {name: 'formsReceived', endpoint: WEB_FORMS},
        {name: 'campagne', endpoint: CAMPAIGN},
    ].map(query => ({
        queryKey: [`report_${query.name.toLowerCase()}`, period] as QueryKey,
        queryFn: (context: QueryFunctionContext<QueryKey, any>) => fetchDashboardData(query.endpoint, period),
        enabled: !!period.start && !!period.end,
        select: (data: any) => {

            switch (query.name) {
                case 'conversion':
                    return data
                case 'seo': {

                    if (data.status === 500)
                        return defaultSeoData
                    else return formatSeoData(data)
                }
                case 'callReceived':
                    return formatCallTracking(data)
                case 'formsReceived':
                    return data.runningTotalFormReceived
                case 'campagne':
                    return formatCampaignData(data)
                default:
                    return data
            }
        },
        onSuccess: (data: any) => onSuccessReq(data, query.name),
        onError,
    })), [period, onSuccessReq, onError])

    useQueries(queries)

    useEffect(() => {
        setData(prevData => ({
            ...prevData,
            loadingStatus: 'pending',
            period
        }))
    }, [period])

    const onSuccess = (data: AdministrationData) => {
        setData(prevState => ({
            ...prevState,
            administration: {data, loading: false},
        }))
    }

    useQuery<AdministrationData, Error>('AdministrationData', fetchAdministrationData, {
        onSuccess,
    })

    useEffect(() => {
        if (id > 0) setLoadPdf('fulfilled')
    }, [id])

    const onSubmit = async (): Promise<void> => {
        setLoadPdf('pending')
        try {
            const response: AxiosResponse<Blob> = await axios({
                method: 'GET',
                url: `${API_URL}${REPORT_PDF}/${id}?start=${period.start}&end=${period.end}`,
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${localStorageValue}`,
                },
            })
            FileDownload({
                data: response.data,
                fileName: `Rapport ${period.start} ~ ${period.end}`,
                fileExtension: 'pdf',
            })
            setLoadPdf('fulfilled')
        } catch (error: any) {
            setLoadPdf('rejected')
        }
    }

    return (
        <ReportContext.Provider value={data}>
            {/* RangeValue */}

            <PageHeader icon={'/media/icons/duotune/general/gen062.svg'}
                        title={'Rapport d’activité Webforce'}
                        description={'Consultez les détails de l’activité de votre site avec des données claires et précises. Analysez le nombre de visites, de pages vues, et de conversions. Retrouvez également des statistiques clés comme les appels, les clics, les formulaires soumis, ainsi que le Top 5 des origines géographiques des visiteurs, des pages les plus consultées, et des canaux de trafic. Ce rapport vous aide à mieux comprendre et optimiser la performance de votre site.'}
            />

            <div className="row mb-8">
                <div className="col-4">
                    <PdfButton loadPdf={loadPdf} onSubmit={onSubmit} />
                </div>
                <div className="col-8 d-flex justify-content-end align-items-center">
                    <DateRange rangeValue={rangeValue}
                               setRangeValue={setRangeValue}
                               loadingStatus={data.loadingStatus}
                               setLoadingStatus={(status) => setLoadingStatus(status)}
                               className="d-flex justify-content-end align-items-center"
                    />
                </div>
            </div>

            <div className={'container card card-custom mt-10 p-10'}>
                {children}
            </div>

        </ReportContext.Provider>
    )
}

export const useReport = () => {
    return useContext(ReportContext)
}

interface PdfButtonProps {
    loadPdf: loadingState
    onSubmit: () => Promise<void>
}

const PdfButton: FC<PdfButtonProps> = ({loadPdf, onSubmit}) => {
    const buttonClass = `btn btn-sm btn-flex fw-bolder btn-light-${
        loadPdf === 'fulfilled' ? 'success' : 'danger'} border-${
        loadPdf === 'fulfilled' ? 'success' : 'danger'} border-1 border-dashed ${
        loadPdf === 'fulfilled' ? '' : 'disabled'
    }`

    return (
        <button className={buttonClass} onClick={onSubmit} type="submit" disabled={loadPdf === 'pending'}>
            <i className="bi bi-file-pdf fs-2" />
            <span>Télécharger mon rapport</span>
            {loadPdf === 'pending' && (
                <span data-kt-indicator="on">
                        <span className="indicator-progress">
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                    </span>
            )}
        </button>
    )
}