import {createContext, Dispatch, SetStateAction, useContext} from 'react'
import {ValueType} from 'rsuite/cjs/DateRangePicker/types'

/**
 * User
 */
export interface Product {
    id: number;
    client_id: number;
    Calltraking: number;
    CalltrakingDate: string | null;
    Webformulaires: number;
    WebformulairesDate: string | null;
    Mailing: number;
    MailingDate: string | null;
    Ereputaion: number;
    EreputaionDate: string | null;
    Statistiques: number;
    StatistiquesDate: string | null;
    created_at: string;
    updated_at: string;
}

export interface ProductListItem {
    id: number;
    name: string;
    description: string;
}

interface CleverphoneItem {
    id: number;
    clients_id: number;
    pays: string;
    numero: string;
    numero_destination: string;
    adr_Msg_vocal: string;
    region: string;
    created_at: string; // Could be Date if you convert it
    updated_at: string; // Could be Date if you convert it
    status: number;
    token: string | null;
}

interface ProductModel {
    id: number;
    name: string;
    description: string | null;
    activated_at: string | null;
}

export interface ActiveProductsType {
    Calltraking: ProductModel;
    Webformulaires: ProductModel;
    Mailing: ProductModel;
    Ereputaion: ProductModel;
    Statistiques: ProductModel;
    Sav: ProductModel;
    UpdatePlugins: ProductModel;
}

const ActiveProducts: ActiveProductsType = {
    Calltraking: {
        id: -1,
        name: 'Calltraking',
        description: '',
        activated_at: null,
    },
    Webformulaires: {
        id: 2 - 1,
        name: 'Webformulaires',
        description: '',
        activated_at: null,
    },
    Mailing: {
        id: -1,
        name: 'Mailing',
        description: '',
        activated_at: null,
    },
    Ereputaion: {
        id: -1,
        name: 'Ereputaion',
        description: '',
        activated_at: null,
    },
    Statistiques: {
        id: -1,
        name: 'Statistiques',
        description: '',
        activated_at: null,
    },
    Sav: {
        id: -1,
        name: 'Sav',
        description: ' ',
        activated_at: null,
    },
    UpdatePlugins: {
        id: -1,
        name: 'UpdatePlugins',
        description: ' ',
        activated_at: null,
    },
}

interface Workspace {
    id: number;
    name: string;
    logo: string;
    color: {
        main: string;
        second: string;
        text: string | null; // Nullable string
    };
    is_active: number; // Can also use boolean if appropriate
}

const workspace: Workspace = {
    id: -1,
    name: "",
    logo: "",
    color: {
        main: "#e1006d",
        second: "#0094f3",
        text: null,
    },
    is_active: 1, // Change to true/false if you switch `is_active` to boolean
};


export interface UserInterface {
    id: number;
    reference: string;
    firstname: string;
    lastname: string;
    usernameCleverphone: string;
    email: string;
    lccmp: number;
    domainName: string;
    phone: string;
    commercialName: string;
    created_at: string;
    updated_at: string;
    status: number;
    deleted_at: string | null;
    remember_token: string | null;
    token_cphone: string;
    mailing_limit: string | null;
    products_list: ProductListItem[];
    images: any[];
    politique_conf: string | null;
    products: Product;
    cleverphone: CleverphoneItem[];
    googleAnalytics: boolean
    products_active: ActiveProductsType
    workspace: Workspace | null
}

export const initialState: UserInterface = {
    id: 0,
    reference: '',
    firstname: '',
    lastname: '',
    usernameCleverphone: '',
    email: '',
    lccmp: 0,
    domainName: '',
    phone: '',
    commercialName: '',
    created_at: '',
    updated_at: '',
    status: 0,
    deleted_at: null,
    remember_token: null,
    token_cphone: '',
    mailing_limit: null,
    products_list: [],
    images: [],
    politique_conf: null,
    products: {
        id: 0,
        client_id: 0,
        Calltraking: 0,
        CalltrakingDate: null,
        Webformulaires: 0,
        WebformulairesDate: null,
        Mailing: 0,
        MailingDate: null,
        Ereputaion: 0,
        EreputaionDate: null,
        Statistiques: 0,
        StatistiquesDate: null,
        created_at: '',
        updated_at: '',
    },
    cleverphone: [],
    googleAnalytics: false,
    products_active: ActiveProducts,
    workspace
}

export interface UserContextInterface {
    personalInformation: UserInterface
    setPersonalInformation: Dispatch<SetStateAction<UserInterface>>
}

export const UserContext = createContext<UserContextInterface>({
    personalInformation: initialState,
    setPersonalInformation: (user) => {}
});
/**
 * Notifications
 */

export const NotificationsInitialState: Partial<NotificationsResponse> = {
    ticket_message: {
        count: 0,
        data: [
            {
                id: -1,
                user_id: -1,
                client_id: -1,
                ticket_id: -1,
                subject: '',
                message: '',
                attachments: [],
                created_at: '',
            },
        ],
    },
}

export interface NotificationsResponse {
    status: number;
    message_code: string;
    ticket_message: TicketMessage;
    // Add other notifications properties if necessary
}

export interface NotificationsContextType {
    notifications: Partial<NotificationsResponse>;
    refreshNotifications: () => void;
}


export interface TicketMessage {
    count: number;
    data: {
        id: number;
        user_id: number;
        client_id: number;
        ticket_id: number;
        subject: string;
        message: string;
        attachments: string[];
        created_at: string;
    }[];
}

export const NotificationsContext = createContext<NotificationsContextType>({
    notifications: NotificationsInitialState,
    refreshNotifications: () => {
    },
})

export const useUser = () => {
    return useContext(UserContext)
}
export const useNotifications = () => {
    return useContext(NotificationsContext)
}


/**
 * GoBack
 */

export interface SettingsContextType {
    settings: Record<string, any>; // Replace 'any' with a more specific type if needed
    setSettings: (url: string, settings: any) => void;
    getSettings: (url: string) => any;
}

export const SettingsContext = createContext<SettingsContextType | undefined>(undefined)

export const useSettings = () => {
    const context = useContext(SettingsContext)
    if (!context) {
        throw new Error('useSettings must be used within a SettingsProvider')
    }
    return context
}